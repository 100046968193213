@import "@savvaslearning/lst-style-variables/variables.scss"; // not required for cel-components but is required in order to utilize the standard set of scss variables within your MFE
@import "@savvaslearning/cel-components/dist-stencil/build/lib.css";
@import "@savvaslearning/cel-components/dist-stencil/lib/fonts/font-open-sans/font-open-sans.css";
@import "@savvaslearning/cel-components/dist-stencil/lib/fonts/font-poppins/font-poppins.css";
@import "styles/themes/typography-variables.css";
@import "styles/themes/color-variables.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background: url("./assets/background/savvas_bg_68_blue_left.svg"),
    url("./assets/background/savvas_bg_68_gray_right.svg");
  background-size: auto 100%, auto 100%;
  background-repeat: no-repeat;
  background-position: -50px center, right center;
  background-color: var(--grayBackground);
  --text-link-focus-outline-color: var(--bluePrimary);
  scroll-behavior: smooth;
  background-attachment: fixed !important;
}

h1,
h2,
h3 {
  font-weight: normal;
}

.block {
  display: block;
}

@media screen and (max-width: 559px) {
  html {
    background: none;
    height: 100%;
  }

  body {
    background-image: url("./assets/background/savvas_bg_68_mobile_gray_right.svg"),
      url("./assets/background/savvas_bg_68_mobile_blue_left.svg");
    background-size: 100%, 100%;
    background-position: center top, center bottom;
    background-attachment: fixed;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  body {
    background: url("./assets/background/savvas_bg_68_tablet_blue_left.svg"),
      url("./assets/background/savvas_bg_68_tablet_gray_right.svg");
    background-size: auto 100%, auto 100%;
    background-repeat: no-repeat;
    background-position: -400px center, center right;
    background-color: var(--grayBackground);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  body {
    background-size: auto 100%, auto 100%;
    background-position: calc(100% - 16em) center, center right;
    background-color: var(--grayBackground);
  }
}

@media only screen 
and (orientation: landscape)
and (min-aspect-ratio: 13/9)
and (max-device-width: 1365px)
and (min-device-height: 560px) {
    body {
      background: url("./assets/background/savvas_bg_68_tablet_blue_left.svg"),
        url("./assets/background/savvas_bg_68_tablet_gray_right.svg");
      background-position: -25em center, center right;
      background-repeat: no-repeat;
    }
}

@media (pointer:none), (pointer:coarse) and (max-device-width: 767px)
{
  body {
    position: relative;
  }
}
