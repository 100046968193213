:root {
  /* Open Sans Fonts */
  --primary-font: "OpenSans";
  --font-open-sans-bold: "OpenSans-Bold";
  --font-open-sans-bold-italic: "OpenSans-BoldItalic";
  --font-open-sans-extra-bold: "OpenSans-ExtraBold";
  --font-open-sans-extra-bold-italic: "OpenSans-ExtraBoldItalic";
  --font-open-sans-italic: "OpenSans-Italic";
  --font-open-sans-light: "OpenSans-Light";
  --font-open-sans-semi-bold: "OpenSans-Semibold";
  --font-open-sans-semi-bold-italic: "OpenSans-SemiboldItalic";
  --font-open-sans-light-italic: "OpenSansLight-Italic";

  /* Poppin Fonts */
  --font-poppin-black: "Poppins-Black";
  --font-poppin-black-italic: "Poppins-BlackItalic";
  --font-poppin-bold: "Poppins-Bold";
  --font-poppin-bold-italic: "Poppins-BoldItalic";
  --font-poppin-extra-bold: "Poppins-ExtraBold";
  --font-poppin-extra-bold-italic: "Poppins-ExtraBoldItalic";
  --font-poppin-extra-light: "Poppins-ExtraLight";
  --font-poppin-light: "Poppins-Light";
  --font-poppin-regular: "Poppins-Regular";
  --font-poppin-semi-bold: "Poppins-SemiBold";
  --font-poppin-semi-bold-italic: "Poppins-SemiBoldItalic";
  --font-poppin-thin: "Poppins-Thin";
  --font-poppin-thin-italic: "Poppins-ThinItalic";
  --font-poppin-medium: "Poppins-Medium.ttf";

  /* Text Sizes */
  --text-size-xxx-large: 1.875rem; /* 1.875rem (30px) */
  --text-size-xx-large: 1.5rem; /* 1.5rem (24px) */
  --text-size-x-large: 1.25rem; /* 1.25rem (20px) */
  --text-size-large: 1.125rem; /* 1.125rem (18px) */
  --text-size-normal: 1rem; /* 1rem (16px) */
  --text-size-small: 0.875rem; /* 0.875rem (14px) */
  --text-size-x-small: 0.75rem; /* 0.75rem (12px) */

  /* Icon Sizes */
  --icon-size-xxx-large: 4.5rem; /* 4.5rem (72px) */
  --icon-size-xx-large: 3.125rem; /* 3.125rem (50px) */
  --icon-size-x-large: 2.25rem; /* 2.25rem (36px) */
  --icon-size-large: 1.875rem; /* 1.875rem (30px) */
  --icon-size-normal: 1.5rem; /* 1.5rem (24px) */
  --icon-size-small: 1.125rem; /* 1.125rem (18px) */
  --icon-size-x-small: 1rem; /* 1rem (16px) */

  /* Layout Variables */
  --corner-radius-button: 2px;
  --corner-radius-input: 4px;
  --corner-radius-panel: 2px;
  --corner-radius-none: 0;
  --corner-radius-circle: 50%;
  --box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  --font-weight-light: 300;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 900;

  /* Letter Spacing Variables */
  --letter-spacing-xxx-small: 0.03125em; /* 0.03125em; (0.5px) */

  --display-override-flex: flex;

  /* Line Height Variables */
  --line-height-normal: 1.063rem; /* 17px */
  --line-height-medium: 1.5rem; /* 26px */
  --line-height-large: 1.687rem; /* 28px */
  --line-height-xxx-large: 1.875rem; /* 30px */
}

@font-face {
    font-family: 'cabinbold';
    src: url('src/assets/fonts/cabin-bold-webfont.eot');
    src: url('src/assets/fonts/cabin-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/cabin-bold-webfont.woff') format('woff'),
         url('src/assets/fonts/cabin-bold-webfont.ttf') format('truetype'),
         url('src/assets/fonts/cabin-bold-webfont.svg#cabinbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cabinitalic';
    src: url('src/assets/fonts/cabin-italic-webfont.eot');
    src: url('src/assets/fonts/cabin-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/cabin-italic-webfont.woff') format('woff'),
         url('src/assets/fonts/cabin-italic-webfont.ttf') format('truetype'),
         url('src/assets/fonts/cabin-italic-webfont.svg#cabinitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cabinregular';
    src: url('src/assets/fonts/cabin-regular-webfont.eot');
    src: url('src/assets/fonts/cabin-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/cabin-regular-webfont.woff') format('woff'),
         url('src/assets/fonts/cabin-regular-webfont.ttf') format('truetype'),
         url('src/assets/fonts/cabin-regular-webfont.svg#cabinregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'cabinsemibold';
    src: url('src/assets/fonts/cabin-semibold-webfont.eot');
    src: url('src/assets/fonts/cabin-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/cabin-semibold-webfont.woff') format('woff'),
         url('src/assets/fonts/cabin-semibold-webfont.ttf') format('truetype'),
         url('src/assets/fonts/cabin-semibold-webfont.svg#cabinsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'cabinsemibold_italic';
    src: url('src/assets/fonts/cabin-semibolditalic-webfont.eot');
    src: url('src/assets/fonts/cabin-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/cabin-semibolditalic-webfont.woff') format('woff'),
         url('src/assets/fonts/cabin-semibolditalic-webfont.ttf') format('truetype'),
         url('src/assets/fonts/cabin-semibolditalic-webfont.svg#cabinsemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'museo_slab_500italic';
    src: url('src/assets/fonts/museo_slab_500italic-webfont.eot');
    src: url('src/assets/fonts/museo_slab_500italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/museo_slab_500italic-webfont.woff') format('woff'),
         url('src/assets/fonts/museo_slab_500italic-webfont.ttf') format('truetype'),
         url('src/assets/fonts/museo_slab_500italic-webfont.svg#museo_slab_500italic') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'museo_slab_500regular';
    src: url('src/assets/fonts/museo_slab_500-webfont.eot');
    src: url('src/assets/fonts/museo_slab_500-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/museo_slab_500-webfont.woff') format('woff'),
         url('src/assets/fonts/museo_slab_500-webfont.ttf') format('truetype'),
         url('src/assets/fonts/museo_slab_500-webfont.svg#museo_slab_500regular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'museo_slab_700regular';
    src: url('src/assets/fonts/museo_slab_700-webfont.eot');
    src: url('src/assets/fonts/museo_slab_700-webfont.eot?#iefix') format('embedded-opentype'),
         url('src/assets/fonts/museo_slab_700-webfont.woff') format('woff'),
         url('src/assets/fonts/museo_slab_700-webfont.ttf') format('truetype'),
         url('src/assets/fonts/museo_slab_700-webfont.svg#museo_slab_700regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
