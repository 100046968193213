:root {
  --white: #fff;
  --black: #000;
  --grayDark: #4c4c4c;
  --grayLightDarkest: #9b9b9b;
  --grayLightDark: #f7f7f7;
  --grayDarker: #333;
  --grayLightest: #f3f3f3;
  --grayLight: #e6e6e6;
  --grayLightWhite: #dddddd;
  --grayLightBlack: #acacac;
  --grayButtonHover: #dadada;
  --grayScale: #767676;
  --graySolid: #282828;
  --grayWhite: #dddddd;
  --grayBlue: #dfe8ed;
  --grayBackground: #f2f2f2;
  --green: #008a00;
  --bluePrimary: #006be0;
  --blueLight: #094fa9;
  --linkBlueLight: #2150a3;
  --brickRed: #cc333f;
  --maroonRed: #d73a59;
  --redLightest: #f3dede;
  --whiteSmoke: #f5f5f5;
  --lightestBlue: #006f8f;
  --mediumBlue: #007a9c;
  --darkerBlue: #005a70;
  --darkestBlue: #003057;
  --disabledBtn: #e2e2e2;
}
